import React, { useEffect, useMemo, useState } from 'react';
import { navigate } from 'gatsby';
import qs from 'qs';

import {
  OFFERS_TAB_INDEX,
  PROPERTY_DETAILS_TABS,
  SEARCH_RESULT_PROPERTY_DETAILS_TABS,
} from '../../constans/search';
import Tabs from '../Tabs/Tabs';
import SearchFilters from '../SearchFilters/SearchFilters';
import Panel from '../Tabs/Panel';
import PropertyDetailsSearchResult from '../PropertyDetails/PropertyDetailsSearchResult/PropertyDetailsSearchResult';
import SearchResultRoomsList from '../RoomItem/SearchResultRoomsList/SearchResultRoomsList';
import SearchResultOffersList from '../RoomItem/SearchResultOffersList/SearchResultOffersList';
import RoomsService from '../../services/rooms.service';
import StorageService from '../../services/storage.service';
import { toggleBodyStylesForModal } from '../../helpers/helper-methods';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';
import ROLES from '../../constans/roles';
import { DEFAULT_NUMBER_OF_ROOMS } from '../../constans/validation';
import PropertyInfoTab from '../PropertyDetails/PropertyInfoTab/PropertyInfoTab';
import PropertyReviewDetails from '../PropertyReviewDetails/PropertyReviewDetails';

import styles from './SearchResultPropertyInfo.module.scss';
import http from '../../services/http.service';
import { API } from '../../constans/http';
import BookPropertyButton from '../BookPropertyButton/BookPropertyButton';
import SignUpModal from '../AutorizationModal/SignUpModal';

function SearchResultPropertyInfo({
  containerStyles,
  switchersStyles,
  requestId,
  GoBackBtn,
  activePropertyId,
  filters,
  activeIndex,
  setActiveIndex,
  filterContainerStyles,
  currency,
  checkin,
  checkout,
  adultGuests,
  kidGuests,
  detailsView = false,
  numberOfRooms = DEFAULT_NUMBER_OF_ROOMS,
  kids,
  buttonsContainerClasses = '',
  bookActivityButtonWrapperClasses = '',
}) {
  const storageService = useMemo(() => StorageService(), []);
  const [rooms, setRooms] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [isModalShown, setIsModalShown] = useState(false);

  const toggleSignInModal = () => {
    toggleBodyStylesForModal();
    setIsModalShown((prevState) => !prevState);
  };

  const getRooms = async (propertyId) => {
    const { data } = await RoomsService()
      .getRoomsByPropertyId({ propertyId, shortResponse: true });

    const processedData = data.map(({ title, id }) => ({
      id,
      title,
      value: `${id}`,
    }));

    setRooms(processedData);
  };

  const handleRoomOffersClick = (id) => {
    setActiveIndex(0);
    setSelectedRooms([`${id}`]);
  };

  const handleAllOffersClick = () => {
    setActiveIndex(0);
    setSelectedRooms([]);
  };

  useEffect(() => {
    if (activePropertyId) {
      getRooms(activePropertyId);
    }
    handleAllOffersClick();
  }, [activePropertyId]);

  const handleSuccess = (offerId) => {
    const query = qs.stringify({
      propertyId: activePropertyId,
      offerId,
      currency,
      checkin,
      checkout,
      adultGuests,
      kidGuests,
      numberOfRooms,
      kids,
    });

    navigate(`/customers/bookings/create?${query}`);
  };

  const handleBookClick = (offerId) => {
    const { role } = storageService.get(LOCALSTORAGE_KEYS.USER, { role: ROLES.GUEST });

    if (role === ROLES.RETAIL_CUSTOMER) {
      handleSuccess(offerId);

      return;
    }

    toggleSignInModal();
  };

  const [propertyData, setPropertyData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (activePropertyId) {
      http
        .get(`${API.PROPERTIES}/${activePropertyId}`)
        .then(({ data }) => {
          setPropertyData(data);
          setLoading(false);
        }).catch(() => setLoading(false));
    }
  }, [activePropertyId]);

  return (
    <div className={containerStyles}>
      <SignUpModal
        isShown={isModalShown}
        toggleModal={toggleSignInModal}
        isSignIn={false}
      />
      { !loading
      && (
      <>
        <div className={styles.showLaptop}>
          {GoBackBtn}
        </div>
        <div className={`${buttonsContainerClasses || styles.headerButtonsContainer} ${activeIndex === OFFERS_TAB_INDEX + 1 && styles.mr10}`}>
          <div className={styles.showOnlyMobile}>
            {GoBackBtn}
          </div>
          <BookPropertyButton
            details={propertyData}
            buttonWrapperClasses={bookActivityButtonWrapperClasses
              || styles.bookActivityButtonWrapper}
          />
        </div>
      </>
      )}
      <Tabs
        switcherStyles={styles.switcher}
        switchers={detailsView ? PROPERTY_DETAILS_TABS : SEARCH_RESULT_PROPERTY_DETAILS_TABS}
        switchersClassName={`${styles.switchersWrapper} ${switchersStyles}`}
        activeClassName={styles.activeSwitchers}
        tabsWithFiltersIds={[OFFERS_TAB_INDEX + 1]}
        Filter={(
          !detailsView && (
            <SearchFilters
              containerClassName={filterContainerStyles}
              togglerClassName={styles.toggler}
              initialValues={{ rooms: [...selectedRooms] }}
              resetState={{ rooms: [] }}
              roomTypes={rooms}
              roomTypesName="rooms"
              onSubmit={(values) => setSelectedRooms(values?.rooms)}
            />
          ))}
      >
        {!loading && selectedRooms.length > 0 && activeIndex === 0
            && (
            <div className={styles.allOffersButton}>
              <div
                onClick={() => {
                  setSelectedRooms([]);
                }}
              >
                All rooms offers
              </div>
            </div>
            )}
        <Panel tabId={1}>
          <PropertyDetailsSearchResult
            detailsView={detailsView}
            onAllOffersClick={handleAllOffersClick}
            propertyId={activePropertyId}
            galleryInnerStyles={styles.galleryInner}
          />
        </Panel>
        <Panel tabId={2}>
          <PropertyInfoTab
            propertyId={activePropertyId}
          />
        </Panel>
        <Panel tabId={3}>
          <SearchResultRoomsList
            detailsView={detailsView}
            onRoomOffersClick={handleRoomOffersClick}
            propertyId={activePropertyId}
            requestId={requestId}
          />
        </Panel>
        {!detailsView && (
          <Panel tabId={0}>
            <SearchResultOffersList
              filters={filters}
              requestId={requestId}
              activePropertyId={activePropertyId}
              selectedRooms={selectedRooms}
              onBookClick={handleBookClick}
              adultGuests={adultGuests}
              kidGuests={kidGuests}
              numberOfRooms={numberOfRooms}
            />
          </Panel>
        )}
        <Panel tabId={detailsView ? 0 : 4}>
          <PropertyReviewDetails propertyId={activePropertyId} />
        </Panel>
      </Tabs>
    </div>
  );
}

SearchResultPropertyInfo.defaultProps = {
  containerStyles: '',
  GoBackBtn: null,
  switchersStyles: '',
  filterContainerStyles: 'col-9 col-md-5 col-lg-4',
};

export default SearchResultPropertyInfo;
